<template>
  <v-container>
    <v-card flat class="elevation-0 pa-3">
      <v-btn
        text
        small
        @click="
          $router.push({
            name: 'InformationAll',
            params: { type: $route.params.type }
          })
        "
        ><v-icon class="mr-2">mdi-arrow-left</v-icon>
        {{ $t("app.back") }}</v-btn
      >
      <v-row justify="center" align="center">
        <v-col cols="12" class="mt-4">
          <v-card
            :class="darkmode ? 'black' : 'white'"
            class="text-left"
            flat
            elevation="0"
          >
            <span class="display-1 font-weight-bold">
              {{ data.title }}
            </span>
            <v-divider class="my-3" />
            <v-row v-if="data.attachment && data.attachment.length > 0">
              <v-col v-if="data.attachment[0].type == 'image'">
                <v-row no-gutters>
                  <v-carousel height="auto">
                    <v-carousel-item v-for="d in data.attachment" :key="d.id">
                      <v-img :src="d.path"></v-img>
                    </v-carousel-item>
                  </v-carousel>
                </v-row>
              </v-col>
              <v-col v-else cols="12" v-for="d in data.attachment" :key="d.id">
                <v-row justify="center">
                  <iframe
                    v-if="d.type == 'youtube'"
                    :src="getUrl()"
                    frameborder="0"
                    style="height: 450px"
                    width="100%"
                    allowfullscreen
                    no-gutters
                  ></iframe>
                </v-row>

                <v-row v-if="d.type == 'pdf'" no-gutters>
                  <object
                    v-if="d.type == 'pdf'"
                    :data="getUrl()"
                    height="450px"
                    width="100%"
                    type="application/pdf"
                  >
                    <iframe
                      :src="
                        `https://docs.google.com/viewer?url=${getUrl()}&embedded=true`
                      "
                    ></iframe>
                  </object>
                </v-row>

                <v-row v-if="d.type == 'video'" no-gutters>
                  <video height="450" width="100%" controls>
                    <source :src="getUrl()" />
                  </video>
                </v-row>
              </v-col>
            </v-row>
            <v-card-text>
              <div class="title">
                <span v-html="data.description" />
                <span v-if="data.description == isFalse">{{
                  $t("homepage.no_description")
                }}</span>
              </div>
              <span class="font-weight-light" style="font-size: 13px">
                {{ $t("app.posted_on") }} {{ data.created_date | dateFormat }}
              </span>
            </v-card-text>
            <v-card-actions class="mx-2" style="font-size: 15px">
              <a class="mr-2">
                <v-icon
                  :color="data.status_likes ? 'primary' : ''"
                  :disabled="!g_token"
                  @click="likeHandler(data.id)"
                  >mdi-thumb-up-outline</v-icon
                >
                <span
                  :style="darkmode ? 'color: white' : 'color: black'"
                  class="ml-1 grey--text"
                  >{{ data.likes }}</span
                >
              </a>
              <a class="mr-2">
                <v-icon>mdi-eye-outline</v-icon>
                <span
                  :style="darkmode ? 'color: white' : 'color: black'"
                  class="ml-1 grey--text"
                  >{{ data.view }}</span
                >
              </a>
              <v-spacer />
              <SocialSharing small :data="setSharing(data)" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-5" />
    </v-card>
  </v-container>
</template>

<script>
import {
  getInformation,
  setLikes,
  getInformationEmployee
} from "@/api/admin/information";
import moment from "moment/moment";
moment.locale("id");
import { mapGetters } from "vuex";

export default {
  components: {
    SocialSharing: () => import("@/components/SocialSharing")
  },
  created() {
    this.route = this.$route.params.type;
    const id = this.$route.query.id;
    if ((this.route === "announcement" || this.route === "news") && id) {
      this.queryData.type = this.route;
      this.queryData.id = id;
      this.getListData();
    }
  },
  computed: {
    ...mapGetters(["g_token"]),
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      route: "news",
      total: 0,
      menu: false,
      isFalse: "",
      data: {},
      queryData: {
        id: null,
        type: "" // announcement or news
      }
    };
  },
  methods: {
    setSharing(data) {
      if (data.id) {
        const setUrl = `${window.location.origin}/information/${
          data.type
        }/detail?id=${data.id}&title=${data.title.split(" ").join("-")}`;
        return {
          url: setUrl,
          title: data.title,
          description: data.description
        };
      }
    },
    getUrl() {
      if (this.data.attachment) {
        if (this.data.attachment.length > 0) {
          const getType = this.data.attachment[0].type;
          if (getType == "youtube") {
            return this.splitUrl(this.data.attachment[0].path);
          } else {
            return this.data.attachment[0].path;
          }
        }
      }
    },
    splitUrl(url, onlyCode) {
      if (url) {
        const splitUrl = url.split("watch?v=");
        if (
          splitUrl[0] == "https://www.youtube.com/" ||
          splitUrl[0] == "www.youtube.com/"
        ) {
          let idYoutube = url.split("v=")[1];
          var ampersAndPosition = idYoutube.indexOf("&");
          if (ampersAndPosition != -1) {
            idYoutube = idYoutube.substring(0, ampersAndPosition);
          }
          if (onlyCode) {
            return idYoutube;
          }
          return "https://www.youtube.com/embed/" + idYoutube;
        }
      }
    },
    likeHandler(id) {
      if (this.g_token) {
        this.$Progress.start();
        setLikes({ id: id }).then(res => {
          if (res.data.code) {
            this.getListData();
          }
          this.$Progress.finish();
        });
      }
    },
    async getListData() {
      let response = [];
      try {
        if (this.g_token) {
          response = await getInformationEmployee(this.queryData);
        } else {
          response = await getInformation(this.queryData);
        }
        if (response.data.code) {
          this.data = response.data.data[0];
          this.total = response.data.data.total;
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: response.data.message,
            color: "error"
          });
        }
      } catch (error) {
        this.$store.commit("CALL_SNACKBAR", {
          msg: error,
          color: "error"
        });
      }
    },
    setFilter(param) {
      console.log(param);
    }
  }
};
</script>
